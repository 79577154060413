.wrapper {
	background-color: var(--white);
}

.container {
	padding: 60px;
	gap: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}

.container img {
	height: auto;
}

@media (max-width: 768px) {
	.container {
		flex-direction: column;
		gap: 20px;
		display: grid;
		grid-template-columns: 1fr 1fr;
		justify-items: center;
		padding: 60px 20px;
	}
}
